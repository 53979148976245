<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :search="search"
            sort-by="order_batch"
            :sort-desc="true"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{$route.name}}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="createBatch" small>
                    <v-icon>mdi-plus</v-icon>
                    Create
                </v-btn>

            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="viewItem(item.order_batch)" class="mr-2">
                    mdi-eye
                </v-icon>
                <v-icon small class="mr-2" @click="editItem(item.order_batch)">
                    mdi-pencil
                </v-icon>
                <v-btn x-small depressed color="green" class="white--text" @click="requestProd(item.order_batch)">Prod</v-btn>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                    Reset
                </v-btn>
            </template>
            <template v-slot:[`item.minDateProd`]="{ item }">
              {{item.minDateProd | moment}} - {{item.maxDateProd | moment}}
            </template>            
            <template v-slot:[`item.delivery_period`]="{ item }">
              {{item.minDateDeliv | moment}} - {{item.maxDateDeliv | moment}}
            </template>            

        </v-data-table>

        <v-overlay :value="onProgress" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        

    </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data: () => ({
      headers: [
        { text: 'Planning Code', value: 'order_batch' },
        { text: 'Periode Produksi', value: 'minDateProd' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      search: '',
      onProgress: false
    }),

    computed: {
    },

    watch: {
    },

    created () {
      this.initialize()
    },
    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        }
    },

    methods: {
      async initialize () {
        let response = await this.$store.dispatch('getObjs', {apidomain:'orders', parameter:''})
        var tRequestProd = []
        if (response.data.status=='success') tRequestProd = response.data.data
        else return console.log(response)
        const batches = [...new Set(tRequestProd.map(item => item.order_batch))];
        var requestProd = []
        batches.forEach(el => {
            var minDateProd='9999-99-99';
            var maxDateProd='0000-00-00';
            var minDateDeliv='';
            var maxDateDeliv='';
            tRequestProd.forEach(req => {
                if (el==req.order_batch && req.production_date<minDateProd){
                    minDateProd = req.production_date
                    minDateDeliv = req.delivery_date
                }
                if (el==req.order_batch && req.production_date>maxDateProd){
                    maxDateProd = req.production_date
                    maxDateDeliv = req.delivery_date
                }
            })
            requestProd.push({
                order_batch: el,
                minDateProd,
                maxDateProd,
                minDateDeliv,
                maxDateDeliv 
            })
        })
        this.items = requestProd
        console.log(requestProd)
      },

      requestProd(batch) {
        this.$router.push('/requestplanningprod/' + batch)
      },

      editItem(batch) {
        this.$router.push('/orderplanningprod/'+batch)
      },

      viewItem(batch) {
        this.$router.push('/orderplanningprod/'+batch)
      },

      async createBatch() {
        this.onProgress = true
        let batch = '001'
        let url = this.$store.state.config.apiHost + "others/getlastbatch"
        let response = await this.$axios.get(url, {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
        const zeroPad = (num, places) => String(num).padStart(places, '0')
        if (response.data.batch!=null) batch = zeroPad(Number(response.data.batch) + 1, 3)
        this.onProgress = false
        this.$router.push('/orderplanningprod/'+batch)
      }

    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>